.Formcontainer {
    /* width: 100%;
    height: 100%;
    position: absolute;
    display: table; */
}

.Inputfield {
    width: 268px;
    height: 40px;
    border: none;
    border-radius: 10px;
    padding-left: 10px;
    background: #f5f5f5;
    outline: none;
    padding-right: 10px;
    font-size: 14px;
    color: #757575;
}

.Inputlabel {
    margin-left: 10px;
    font-size: 14px;
    margin-bottom: 8px;
}

.Inputwrapper {
    text-align: left;
    padding: 5px;
}

.Inputarea {
    width: 268px;
    height: 90px;
    border: none;
    resize: none;
    border-radius: 10px;
    padding-left: 10px;
    padding: 10px;
    background: #f5f5f5;
    outline: none;
    padding-right: 10px;
    font-size: 14px;
    color: #757575;
}

.Inputstreetnumber {
    width: 80px;
    height: 40px;
    border: none;
    margin-left: 8px;
    border-radius: 10px;
    padding-left: 10px;
    background: #f5f5f5;
    outline: none;
    padding-right: 10px;
    font-size: 14px;
    color: #757575;
}

.Inputstreetname {
    width: 180px;
    height: 40px;
    border: none;
    border-radius: 10px;
    padding-left: 10px;
    background: #f5f5f5;
    outline: none;
    padding-right: 10px;
    font-size: 14px;
    color: #757575;
}

.Inputtime {
    width: 100px;
    height: 40px;
    border: none;
    border-radius: 10px;
    padding-left: 10px;
    background: #f5f5f5;
    outline: none;
    padding-right: 10px;
    font-size: 14px;
    color: #757575;
    margin-bottom: 11px;
}

.Opentable {
    font-size: 13px;
    margin-left: 10px;
    padding-bottom:10px;
}

.Completebutton {
    border-radius: 10px;
    border: none;
    color: white;
    width: 200px;
    margin-top: 10px;
    height: 50px;
    outline: none;
    cursor: pointer;
    user-select: none;
    font-size: 19px;
    font-family: Inter;
    background: linear-gradient(314deg, rgba(91,115,41,1) 35%, rgba(163,166,91,1) 100%);    
}

.Inputcurrency {
    width: 160px;
    height: 40px;
    padding-left: 10px;
    border: none;
    border-radius: 10px 0px 0px 10px;
    padding-right: 10px;
    outline: none;
    font-size: 14px;
    color: #757575;
    background: #f5f5f5;
}

.Inputcheckbox {
    width: 25px;
    height: 25px;
    border: none;
    border-radius: 10px;
    outline: none;
}

.Fromwrapper {
    margin: auto;
    text-align: center;
    width: 900px;
    margin-top: 80px;
    height: 840px;
    margin-bottom: 100px;
}

.Formsectionwrapper {
    width: 280px;
    float: left;
    margin-left: 10px;
    margin-right: 10px;
}

.Inputlocationwrapper {
    text-align: left;
    padding: 5px;
}

.Addresslabel {
    width: 100%;
}

.Checkboxwrapper {
    margin-left: 4px;
    margin-top: 20px;
    display: flex;
    float: left;
}

.Checkboxlabel {
    margin-left: 4px;
    font-size: 14px;
    padding: 4px;
}

.Userpicwrapper {
    width: 204px;
    height: 194px;
    background: linear-gradient(314deg, rgba(91,115,41,1) 35%, rgba(163,166,91,1) 100%);    
    border-radius: 10px;
    display: inline-flex;
    position: relative;
}

.Userimagewrapper {
    width: 163px;
    height: 163px;
    border-radius: 50%;
    margin: auto;
    background: white;
    display: flex;
}

#UserImage {
    object-fit: cover;
    width: 163px;
    height: 163px;
    border-radius: 50%;
    z-index: 0;
    position: relative;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
}

.Profileimagebutton {
    width: 163px;
    height: 163px;
    border-radius: 50%;
    cursor: pointer;
    border: none;
    z-index: 1;
    position: absolute;
    background-color: transparent;
}

#ProfileImage {
    display: none;
}

.Completeuserimagewrapper {
    width: 281px;
    height: 100%;
    padding-bottom: 20px;
}

.Errorstyleimage {
    font-size: 13px;
    color: red;
    left: 0;
    margin-top: 180px;
    position: absolute;
}

.Currencyoptions {
    width: 110px;
    height: 40px;
    border: none;
    background: #f5f5f5;
    outline: none;
    border-radius: 0px 10px 10px 0px;
    font-size: 14px;
    color: #757575;
}

.StripeSettings {
    border-radius: 10px;
    border: none;
    color: white;
    width: 268px;
    height: 40px;
    outline: none;
    cursor: pointer;
    user-select: none;
    font-size: 15px;
    font-family: Inter;
    background: linear-gradient(314deg, rgba(91,115,41,1) 35%, rgba(163,166,91,1) 100%);
}